@import "sass-lib";
@media all and(max-width: 767px) {
	.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}

	body {
		&.bg {overflow: hidden;}
	}
	header{
		height: 38px;
		.wrapper{
			padding: 0 20px;
			.rh_head{
				&:before {content:""; left: 0; right: 0; top: 0; height: 40px; position: fixed; display: none; background: #FFFF00; z-index: 8;}
				nav{
					ul{
						&.mmobile{ width: 100%; }
					}
					.thumb-header{
						.sign{
							a{ 
								font-size: 10px; padding: 0; display: block;
								img{ width: 23px; display: inline-block; vertical-align: middle; }
							}
						}
					}
					.burg_nav{
						width: 24px;
						span{ margin-bottom: 5px; }
					}
					a {height: auto; font-size: 18px; padding: 0;}
					.mmobile {
						padding: 50px 25px;
						li {
							padding-left: 0;
							a {font-size: 18px;}
							.subdrop  {
								padding-bottom: 0;
								ul {
									padding: 10px 10px 0;
									li {
										padding: 0;
										a {font-size: 14px; padding: 2px 0;}
									}
								}
							}
						}
					}
				}
			}
			.logo{ 
				z-index: 9; 
				img{ width: 82px; }
			}
		}
		&.bg {
			position: fixed;
			.wrapper {
				.rh_head {
					&:before {display: block;}
				}
			}
		}
	}
	.bodysec{
		padding-top: 38px; overflow-x: hidden;
		.home-slide{
			.wrapper{
				margin: 0 20px;
				.slider{
					.slide{
						.caption{
							width: 80%; z-index: 3; left: 30px;
							h5{ font-size: 8px; }
							h1{ font-size: 25px; line-height: 30px; margin-bottom: 10px;}
							p{ font-size: 10px; line-height: 15px; }
						}
					}
				}
				.nav_slide{ 
					width: 180px; height: 87px; margin-right: -20px;
					.col{
						.list{
							padding: 0 20px; margin-top: 10px;
							p{ font-size: 10px; line-height: 15px; height: 60px; min-height: unset; }
							&:after {display: none;} 
						}
					}
				}
			}
			.slick-arrow {
				width: 18px; height: 18px; left: 30px; bottom: 15px;
				&.slick-prev {bottom: 45px;}
			}
			&:before {content: ""; right: 0; width: 50%; background: #FFFF00; position: absolute; top: 0; bottom: 0; }
		}
		.home-about{
			padding-top: 25px;
			&:before{ width:128px; height: 109px;left: 20px; }
			.wrapper{
				margin: 0 20px; padding:0 21px;
			}
			.tag {display: none;}
			.right{
				margin-right: 0;
				.content{
					h5{ margin-left: 13px; margin-bottom: 10px;}
					p{ font-size: 10px; line-height: 16px; height: unset; margin-left: 13px; margin-bottom: 20px; }
					.btn_round{ margin-left: 13px; }
					* {position: relative; z-index: 5;}
					&:after {z-index: 2;}
				}
			}
			.left{ width: 100%; }
		}
		.home-map{
			.wrapper{
				&:before{ left:20px; right: 20px; height: 100px; bottom: -5px; }
				.map{ height: 241px; margin: 0 40px; }
			}
		}
		.home-service{
			margin: 45px 45px 0 45px;
			.highlight{
				.right{
					p{ font-size: 10px; line-height: 16px; margin-left: 13px;}
				}
			}
			.box-layanan{
				.list{
					.content{
						.left{
							h4{ height: unset; overflow:unset; }
						}
					}
				}
			}
		}
		.home-promote{
			.slider{
				.slide{
					.images{
						img{}
					}
					.content{
						right: 15px;
						.title{ font-size: 17px; margin-bottom: 12px;}
					}
				}
			}
		}
		.home-news{
			.wrapper{
				margin: 0 30px; padding-bottom: 50px;
				.list{
					.content{
						margin-left: 20px;
						h5{ font-size: 10px; }
						h4{ font-size: 13px; line-height: 18px; height: auto; overflow:unset;}
						p{ font-size: 10px; line-height: 15px; height: auto; }
					}
				}
				.col{
					.slick-arrow{ right: -20px; }
				}
			}
		}
		.home-client{
			.wrapper{
				margin: 0 30px;
				.right{
					p, ul li{ font-size: 10px; line-height: 18px; height: 40px; margin-left: 13px; }
					.btn_round{ margin-left: 13px; }
				}
				.left{
					.slider{
						padding-left: 20px; margin-top: 30px;
						.slick-arrow{
							left: -10px; width: 22px; height: 22px;
							&.slick-prev{ bottom: auto; top: 23%;}
							&.slick-next{ bottom: 23%; }
						}
						&:after {right: 8px; width: 30px; top: 49.9%;}
						&:before {left: -10px; width:30px; top: 49.9%;}
					}
				}
				.tag {display: none;}
			}
		}
		.title{ 
			font-size: 20px;line-height: 27px; padding-left: 10px; margin-bottom: 10px;
			&:before{ height:35px; }
		}
	}
	.btn_round{ font-size: 12px; line-height: 18px; padding: 6px 30px;  }
	.middle{ 
		padding-top: 38px; 
		.wrapper{
			margin: 0 20px;
		}
		&.pattern-right {overflow: hidden;}
	}
	.banner{
		.capt_abs{ 
			width: 70%; left: 29px; 
			h6{ font-size: 8px; }
			h1{ font-size: 25px; line-height: 25px; }
			p{ font-size: 10px; line-height: 16px; }
		}
	}
	.inner-content{
		padding: 28px 21px;
		.bg_top_left{
			max-height: 10%;
			&.long{ width: 129px; height: 156px;  }
		}
		.tittle-content{
			h2{ 
				font-size: 20px; line-height: 27px; padding-left: 10px;
				&:before{ height:35px; }
			}
		}
		p, ul li{ font-size: 10px; line-height: 16px; }
	}
	.content-style{
		p, ul li, ol li{ font-size: 10px; line-height: 16px; }
		ol li, ul li{ margin-left: 0; }
		ul{ margin-left: 0 }
		ol{ margin-left: 10px; }
		ul li:before{ top: 6px; }	}
	.headline-content{
		margin-bottom: 30px;
		.left{
			.title{
				font-size: 20px; line-height: 27px; padding-left: 10px;
				&:before{ height:35px; }
			}
		}
		.right{
			p{ font-size: 10px; height: auto; }
		}
	}
	.milestone{
		.col{
			h3{
				font-size: 15px;
			}
			.box{
				padding-top: 30px; padding-bottom: 110px;
				.right{
					.content{
						margin-top: 10px;
						ul li{
							h5{ font-size: 11px; line-height: 17px; }
							p{ font-size: 10px; line-height: 16px; }
						}
					}
				}
			}
			&:first-child{
				.box{
					&:after{ display:none; }
				}
			}
			&:nth-last-child(2){
				.box{ margin-bottom: 30px; }
			}
		}
		tooltip:hover span{ width: 200px; }
	}
	.banner-bottom-abs{
		margin: 0 -20px; height: auto;
		figure{
			.bg{ 
				width: 50%; height: 320px;
				&:before{}
			}
			img {height: 320px;}
		}
		.text{
			right: 13px; width: 40%; min-width: 150px;
			ul {
				margin-left: 0;
				li{ font-size: 8px; line-height: 12px; }
			}
		}
	}
	.detail-page{
		.dt-event{ margin-bottom: 15px; font-size: 8px; }
		.lf-content > h1{ font-size: 20px; margin-bottom: 15px; }
		&.det-event{
			.lf-content{ 
				padding-right: 0;
			}
		}
	}
	.box-table{ overflow-x: auto; position: relative; }
	.sub-detail{
		font-size: 11px; margin-bottom: 15px;
	}
	.btm_share{
		padding-top: 20px; margin-top: 20px; 
		.left, .right{ float: none; display: block; }
		.right{ margin-top: 20px; }
		a{ width: 100%; }
	}
	.bg-yellow-homepage{ height: 11%; }
	.bg-yellow-small{ height: 250px; max-height: 17%; }
	.headline-content{
		.right{
			.box-form{
				.row{
					label{ font-size: 12px; line-height: 18px; padding: 0 10px;}
					select{ width: 100%; margin-left: 0px; display: block; margin-bottom: 10px;  }
					select {height: 36px; font-size: 12px; padding: 6px 30px 6px 20px;}
					.col{ float: none; display: block; width: 100%; }
				}
			}
		}
	}
	.box-blog{
		.col{
			.content{
				padding: 27px 10px;
				h3{ font-size: 20px; }
				h4{ font-size: 13px; line-height: 18px; height: unset; }
				p{ font-size: 10px; line-height: 16px; }
				.right{ padding-left: 20px; }
			}
		}
	}
	.box-event{
		.list{
			margin-bottom: 30px; width: 100%;
			.content{
				margin-left: 15px; margin-bottom: 30px;
				h3{ font-size: 25px; margin-bottom: 15px; }
				h5{ font-size: 8px;  }
				h4{ font-size: 13px; line-height: 18px; margin-bottom: 15px;}
				p{ font-size: 10px; line-height: 16px; margin-bottom: 15px; }
			}
		}
	}
	.title-pg{
		font-size: 20px; line-height: 27px; padding-left: 10px;
		&:before{ height:35px; }
	}

	.ls_block {
		.item {
			margin-bottom: 20px;
			&.tagleft {
				@extend .flex-list;
				.img {order: 2;-webkit-order: 2; -moz-order:2; -ms-order: 2; -o-order: 2; margin-top: 20px;}
				.dec_item {order: 1;-webkit-order: 1; -moz-order:1; -ms-order: 1; -o-order: 1;}
			}
			.tag {display: none;}
		}
	}
	.ls_faci{
		padding-bottom: 30px; box-sizing: border-box;
		li{ width: 100%; margin-right: 0 !important; margin-left: 15px !important; }
	}
	.bg_bf{ 
		height: 240px; display: none;
		&:after{ top:-80px; height: 80px; }
	}
	.wrap-bot {margin-top: 30px;}
	.top-title .title-pg.left {width: auto;}
	.wrap-lab{ height: 550px; }
	.slider-client-misi {
		padding-right: 30px; position: relative; margin: 30px auto 60px;
		.list {
			.col {
				border: 1px solid #ededed; width: 100%; height: 150px;
				figure {
					img {@include transform(scale(0.6));}
				}
				span {
					&:before,&:after {font-size: 32px;top: -18px; right: -9px;}
					&:after {top: auto; bottom: -16px;}
				}
				&:hover {
	                figure {
	                    img { @include transform(scale(0.6));}
	                }
	            }
			}
		}
		.slick-arrow {width: 26px; height: 26px; background-size: 100%; right: 0;}
	}

	.row-head {
		.tittle-content {float: none; display: block;}
		.right-filter {
			float: none; display: block; margin-top: 20px;
			label {margin-top: 15px; display: inline-block; font-size: 12px;}
			select {height: 36px; font-size: 12px; padding: 6px 30px 6px 20px; float: right; width: 60%; min-width: 175px;}
		}
	}

	.qoutes {
		.slider-client-qoutes {
			width: auto; margin-top: 20px; padding: 0 20px 5px; margin-right: -10px;
			.list {
				display: block;
				figure {
					@include transform(scale(0.9)); display: block; padding: 0 5px;min-height: 25px; text-align: center;
					img {display: inline-block;}
				}
			}
		}
		.slider-qoutes {
			.list {
				padding: 40px 0 0;
				&:after, &:before {font-size:70px; line-height: 60px;}
			}
			.author {font-size: 12px;}
			p {font-size: 12px; line-height:18px; }
		}
		.banner-client {
			padding-top: 30px;
			h2 {font-size: 20px;}
		}
		.bg {
			height: 135px;
			&:after {height: 100%;}
		}
	}

	.box-form {
		.row {
			margin-bottom: 15px;
			label {font-size: 12px; padding: 0 20px;}
			input[type="text"], input[type="email"],input[type="password"],input[type="number"] {
				height: 36px; font-size: 12px; padding: 6px 20px;
			}
			select {height: 36px; font-size: 12px; padding: 6px 30px 6px 20px;}
		}
		&.brder {
			.row {
				margin-bottom: 15px;
				.col {
					&.col5 {margin-bottom: 15px;}
				}
				select {height: 36px; font-size: 12px; padding: 6px 30px 6px 20px;}
			}
		}
	}

	.wrap_signin {
		padding-top: 38px;
		.v-text {top: 0; @include transform(translateY(0));}
		.content-signin {
			height: 80vh; min-height: 500px; padding-top: 40px;
			.v-text {
				h1 {font-size: 30px; margin-bottom: 10px;}
				p {font-size: 12px; margin-bottom: 30px;}
			}
			.box-form  {
				.row {
					margin: 0 -10px 15px;
					.col-center {text-align: center;}
					.info-sign {margin: 10px 0 30px;}
				}
			}
			&.regist {height: auto;}
		}
	}
	.copyright p {font-size: 12px;}

	.packet-box {
		.col {
			display: block;
			.list {
				margin: 0 0px 30px; padding: 30px 30px 130px; width: 100%; @include boxSizing(border-box);
				.content {
					margin-bottom: 60px;
					ul {
						display: block;
						li {}
					}
				}
				.not-price {bottom: 85px; font-size: 12px;}
				.btn_round {padding: 6px 34px; left: 0; right: 0; @include transform(translateX(0)); max-width: 200px; margin: auto;}
			}
		}
	}
	.quote {
		padding: 70px 25px 50px;
		h4 {font-size: 13px;}
	}
	.wrap_pop{
		.pop_inner{
			.content_pop{
				width: 400px; margin: 8% auto;
				.capt{ 
					width: auto; position: relative; padding: 15px;
					p{ font-size: 16px; }
				}
				.img_vid{
					iframe{ height: 230px; }
				}
				.close{ font-size: 12px; width: 30px; height: 30px; background-size: 35px; top: -30px;  }
			}
		}
		.slide_Thumb{ 
			width: auto;
			.thumb{
				img{ width: 110px; }
			} 
		}
		.galery_pop{
			.slick-arrow{
				top: 20%;
				&.slick-prev{ left: 20px; background-size: 20px; }
				&.slick-next{ right: 20px;  background-size: 20px;}
			}
		}
	}
	.wrap_pop{
		.slide_Thumb{
			.thumb{ margin: 0 5px; }
		}
	}
	.branch-address{
		.col{
			.left, .right{ float: none; width: 100%; }
		}
	}
	.static-pages{
		.wrapper{
			padding: 30px;
		}
		.images{
			.bg-img{ height: 700px; }
		}
		.content{
			width: 400px;
			h1{ font-size: 35px; line-height: 35px; }
		}
	}
	.bg-yellow-bot{
		&.aboutus{ display: none; }
	}
	.map-banner{
		.find-loc{ position: relative; padding: 30px; height: auto; }
		.map-wrap{ height: 300px; }
	}
	.g-recaptcha{ transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0; }
	 .wrp-contact{ padding-bottom: 30px; }
	.sertifikat{ margin-bottom: 0px; }
	.inner-content{
		.content{
			margin-bottom: 30px;
		}
	}
	.pagination{
		a.arr{ margin:0; }
	}
	.wrap-lab{
		.wrp-images{ left: 0%; }
	}
	.slideFacilities{
		.slide{
			.count-dot{
				font-size: 100px; right: 100px; top: 0px;
			}
		}
	}
	.box-form{
		&.brder{
			.row{
				&:last-child{
					.col5{ width: 100%; }
					.col{
						.btn_round{ float: none; margin-top: 0; }
					}
				}
			}
		}
	}
	footer{
		.wrapper{
			margin: 0 20px;
		}
		.darkbox{
			padding: 20px;			
			.top-footer{
				margin-bottom: 15px;
				ul li{
					padding: 0 10px 0px 10px;
					a{ font-size: 8px; }
				}
			}
			.middle-footer{
				.col{
					.title{ font-size: 10px; line-height: 14px; }
					p, ul li{ font-size: 8px; line-height: 12px; }
					&:after{ height: 20px; }
				}
			}
		}
		.bottom-footer{
			p{ font-size: 8px; }
		}
	}
}
@media all and(max-width: 620px) {

	.wrap-lab {
		height: 415px;
		.wrp-images {
			@include transform(scale(0.75)); margin: 60px auto !important; width: 400px;
			figure {height: 500px; padding: 190px 19px 0;}
		}
		.wrp-absolute {
			.abs {
				&.img1 {left: -80px;}
				&.img2 {}
				&.img3 {right: -90px;}
			}
		}
	}

	.ls_faci {
		padding-left: 15px;
		li {
			width:100%; margin: 0 8% 15px 0 !important; 
			&:nth-child(3n) {margin-right: 8% !important;}
			&:nth-child(2n) {margin-right: 0 !important;}
		}
	}
	.inner-content .ls_faci {
		margin-top: 40px !important; padding-bottom: 10px !important;
		li {font-size: 11px; line-height: 16px;}
	}
	.bg_bf {
		&:after {height: 100px; top: -70px;}
	}
	.bodysec .home-client .wrapper .left .slider:after{ right: -1px; }


}
@media all and(max-width: 560px) {
	.wrap-lab{ 
		height: 350px; 
		.wrp-images{
			transform: scale(0.6);
		}
	}
	.detail-page{
		&.det-event{
			.lf-content{
				iframe{ height: 230px; }
			}
		}
	}
}
@media all and(max-width: 479px) {

	.wrap-lab{
		height: 160px; top: -180px;
		.wrp-images{ left: -30px; transform: scale(0.4); }
	}

	.wrap_signin {
		.content-signin {
			.box-form {
				.row {
					.col {
						&.col5 {
							.btn_round {padding: 5px 20px; margin-top: 20px;}
						}
					}
				}
			}
		}
	}
	.wrap_pop{
		.pop_inner{
			.content_pop{
				width: 300px;
			}
		}
	}
	.bodysec .home-client .wrapper .left .slider:after{ right: -9px; }


	/* footer
----------------------------------------------------------------------------------------------*/

	.copyright p {font-size: 10px;}
	.copyright {
		text-align: center;
		.wrapper {padding: 0 30px;}
		.cr_lft, .site_rh {float: none; display: inline-block;}
	}
	.static-pages{
		.wrapper{
			padding: 30px;
		}
		.images{
			.bg-img{ height: 650px; }
		}
		.content{
			width: 300px;
			h1{ font-size: 35px; line-height: 35px; }
		}
	}
	.wrap-lab{ 
		height: 250px; 
		.wrp-images{
			left: -30%; transform: scale(0.4) translateX(70%);
		}
	}
	footer {
		.darkbox {
			.middle-footer {
				.col {
					.left,.right {float: none; display: block; width: 100%;}
					.right {margin-bottom: 15px;}
				}
			}
		}
		.bottom-footer {
			margin: 15px 0 0; text-align: center;
			.left,.right {float: none; display: inline-block;}
		}
	}
}
@media all and(max-width: 400px) {
	.wrap-lab{ 
		height: 200px; 
		.wrp-images{
			left: -50%; transform: scale(0.4) translateX(65%);
		}
	}
	.ls_faci{
		padding-top: 0;
	}
	.detail-page{
		&.det-event{
			.lf-content{
				iframe{ height: 150px; }
			}
		}
	}
	.static-pages{
		.wrapper{
			padding: 30px;
		}
		.images{
			.bg-img{ height: 610px; }
		}
		.content{
			width: 250px;
			h1{ font-size: 24px; line-height: 24px; }
		}
	}
	.milestone{
		tooltip{
			span{ margin-left: -50px; }
			&:active,&:hover,&:focus{
				span{ width: 100px; }
			}
		}
	}
	.slideFacilities{
		.slide{
			.bx-slide{ 
				display: block; 
				.dec-facilities{
					.txt{ top: 0; @include transform(translateY(0)); }
				}
			}
			.count-dot{
				font-size: 100px; right: 100px; top: 0px;
			}
		}
	}
	.bodysec .home-client .wrapper .left .slider:after{ right: -14px; }
}
@media all and(max-width: 350px) {
	.wrap-lab{ 
		.wrp-images{
			left: -50%; transform: scale(0.4) translateX(25%);
		}
	}
}